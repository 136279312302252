.cell-data-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.relay-switch,
.relay-data {
  display: flex;
  align-items: center;
}

.relay-status {
  font-size: 1em;
  margin-right: 10px; /* Adjust margin as needed */
}

.relay-data p {
  margin: 0;
  font-size: 1.1em; /* Match the font size of relay status */
}

.data-points-input {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f7f7;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.data-points-input label {
  margin-right: 10px;
}

.data-points-input input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px; /* Add margin-right to input */
}

.data-points-input button {
  padding: 5px 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.data-points-input button:hover {
  background-color: darkblue;
}

.fast-discharge-message {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

.charts {
  display: flex;
  flex-direction: column; /* Ensure the charts are in column */
  gap: 20px; /* Adjust spacing between charts */
  margin-top: 20px;
  margin-left: 20px;
}

.voltage-chart {
  width: calc(90% + 90px); /* Increase the width of the voltage chart */
  margin-left: 10px; /* Center the voltage chart */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.side-by-side-charts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.chart-title {
  background-color: #f7f7f7; /* Background color similar to the header */
  color: black; /* Adjust text color as needed */
  padding: 10px; /* Adds padding around the text */
  border-radius: 8px; /* Rounds the corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
  margin-bottom: 10px; /* Space between title and chart */
}

.chart {
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
  flex: 1; /* Ensure it scales properly */
  height: 400px; /* Ensure the height is consistent */
}

.chart-title {
  color: black; /* Change text color to black */
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: white;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.alerts-box {
  margin-left: 5px;
  color: black;
  background-color: #f7f7f7;
}

/* Small devices (landscape phones, less than 600px) */
@media (max-width: 599px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .data-points-input {
    flex-direction: column;
    align-items: flex-start;
  }

  .charts {
    grid-template-columns: 1fr;
  }

  .chart {
    margin-bottom: 20px;
  }
}

/* Medium devices (tablets, 600px and up) */
@media (min-width: 600px) and (max-width: 991px) {
  .charts {
    grid-template-columns: 1fr;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  .charts {
    grid-template-columns: 1fr;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .charts {
    grid-template-columns: 1fr;
  }
}

body {
  background-color: #e8f5fe; /* Light blue background color */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
