/* General Styling for Sections */
section {
    padding: 50px 0;
    background-color: #fff;
}

section.bg-light {
    background-color: #f8f9fa;
}

/* Typography and Color Scheme */
h2 {
    color: #007bff; /* Blue color for h2 */
    font-size: 2rem; /* Larger font size for h2 */
    font-weight: bold; /* Bold font weight for h2 */
    margin-top: 0;
    margin-bottom: 20px; /* Spacing after h2 */
}

h3 {
    color: #0056b3; /* Slightly darker blue for h3 */
    font-size: 1.5rem; /* Smaller font size for h3 */
    margin-top: 30px; /* Space above h3 */
    margin-bottom: 10px; /* Spacing after h3 */
}

h4, h5 {
    color: #007bff; /* Blue color for h4 and h5 */
    font-weight: bold;
    margin-top: 10px;
}

p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 15px;
}

/* Custom Features, Sector Items, and Overview Section */
.custom-feature, .sector-item, .overview-item {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
}

.sector-item:hover, .overview-item:hover {
    background-color: #e0e0e0;
}

/* Contact Call-to-Action */
.contact-call-to-action {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

/* Footer */
footer {
    background-color: #f8f9fa;
    color: #333;
    padding: 20px 0;
}


.ais-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.ais-item {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.ais-item:hover {
    transform: scale(1.05);
}


@media (max-width: 768px) {

}

/* Responsive Grid for Overview Section */
/* Responsive Grid for Overview Section */
@media (min-width: 768px) {
    .overview-item {
        margin-bottom: 30px;
    }

    .ais-grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Example for multiple columns */
    }
}

/* Adjustments for smaller screens */
@media (max-width: 767px) {
    .ais-grid {
        grid-template-columns: 1fr; /* Single column layout for smaller screens */
    }
}

/* Additional Styling */
.custom-feature {
    border-left: 4px solid #007bff; /* Blue border on the left */
}



