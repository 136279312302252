.sidebar {
  height: 100vh;
  padding-top: 20px;
}

.sidebar .nav-link {
  font-size: 1.1em;
  padding: 15px 20px;
}

.content {
  padding: 20px;
  background-color: #f8f9fa;
  height: 100vh;
  overflow-y: auto;
}
