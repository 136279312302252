.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-indicator .step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.step-indicator .step.completed {
  background-color: #007bff;
}

.step-indicator .line {
  flex-grow: 1;
  height: 2px;
  background-color: #e0e0e0;
  margin: 0 5px;
}

.step-indicator .step.completed + .line {
  background-color: #007bff;
}

h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  font-size: 14px;
  text-align: right; /* Ensure the text is right-aligned */
}

.form-control {
  font-size: 14px;
}

/* .step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-indicator .step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-indicator .step.completed {
  background-color: #007bff;
}

.step-indicator .line {
  flex-grow: 1;
  height: 2px;
  background-color: #e0e0e0;
  margin: 0 5px;
}

.step-indicator .step.completed + .line {
  background-color: #007bff;
} */
