.menu-container {
  position: relative;
}

.menu-trigger {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}

.dropdown-menu.active {
  display: block;
}

.dropdownItem {
  list-style: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #007bff;
  color: white;
}
.userNa {
  align-items: center;
}
.loginformbutton {
  background-color: #0056b3;
  color: antiquewhite;
  font-size: large;
}
